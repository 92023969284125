export interface nextInstallmentProps {
    interestRate: number;
    amount: number;
    installments: number;
}

export interface rateProps {
    rateValue: number;
    interest: number;
    capital: number;
}

export const formattedCurrency = (value: number): string => {
    return new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(value);
}

export const formattedPercentage = (value: number): string => {
    return new Intl.NumberFormat('pl-PL', { style: 'percent'}).format(value);
}

const summary = (r: number, n: number, k: number): number => {
    let sum = 0;
    for (let i = 1; i <= n; i++) {
        sum += Math.pow((1 + (r / k)), -i);
    }
    return sum;
}


const rate = (amount: number, interestRate: number, installments: number): rateProps => {
    const ratesInYear = 12;
    const interestRateFormatted = interestRate / 100;

    const rateValue = amount / summary(interestRateFormatted, installments, ratesInYear);
    const interest = amount * (interestRate / 100) / 12;
    const capital = rateValue - interest;

    return {rateValue, interest, capital};
}

export default rate;