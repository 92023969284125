import React from 'react';
import Calc from './components/Calc';
import './App.css';

function App() {

  return (
    <div className="app">
      <Calc />
    </div>
  );
}

export default App;
