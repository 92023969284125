import { useState } from "react";
import rate from "../helpers/calc";
import Summary from "./Summary";
import RatesTable from "./RatesTable";

const Calc = () => {
    const [amount, setAmount] = useState(500000);
    const [installments, setIntallments] = useState(360);
    const [interestRate, setInterestRate] = useState(8.03);
    const [overpay, setOverpay] = useState(0);
    const [overpayPlus, setOverpayPlus] = useState(false);

    const handleChangeOverpayPlus = (v: boolean) => {
        setOverpayPlus(!overpayPlus);
    }

    const handleChange = (value: number | null, field: 'amount' | 'installments' | 'interestRate' | 'overpay') => {
        switch (field) {
            case 'amount':
                setAmount(value || 0);
                break;
            case 'installments':
                setIntallments(value || 0);
                break;
            case 'interestRate':
                setInterestRate(value || 0);
                break;
            case 'overpay':
                setOverpay(value || 0);
                break;
        }
    }

    const {rateValue, interest, capital} = rate(amount, interestRate, installments);
    const ratesSum = rateValue * 360;

    

    return (
        <div className={'calcWrapper'}>

            <h2>Wartości początkowe</h2>
            <label htmlFor={'amount'}>Wartość kredytu:</label>
            <input type={'number'} id={'amount'} onChange={(e) => handleChange(parseFloat(e.target.value), 'amount')} value={amount} step={1000}/>
            
            <label htmlFor={'installments'}>liczba rat:</label>
            <input type={'number'} id={'installments'} onChange={(e) => handleChange(parseFloat(e.target.value), 'installments')} value={installments} step={12} />
            <label htmlFor={'interrestRate'}>oprocentowanie:</label>
            <input type={'number'} id={'interrestRate'} onChange={(e) => handleChange(parseFloat(e.target.value), 'interestRate')} value={interestRate} step={0.1} />
            <br/>
            <Summary amount={amount} rateValue={rateValue} ratesSum={ratesSum}/>
            <br/>
            <label htmlFor={'overpay'}>nadpłata miesięczna:</label>
            <input type={'number'} id={'overpay'} onChange={(e) => handleChange(parseFloat(e.target.value), 'overpay')} value={overpay} step={500} />
            <input type={'checkbox'} id={'overpayPlus'} onChange={(e) => handleChangeOverpayPlus(!overpayPlus)} value={overpayPlus ? 'checked' : ''} />
            <label htmlFor={'overpayPlus'}>nadpłacaj różnicę w racie:</label>
            <br/>
            <RatesTable amount={amount} installments={installments} interestRate={interestRate} overpay={overpay} overpayPlus={overpayPlus} />
        </div>
    );
}

export default Calc;