import {formattedCurrency, formattedPercentage} from "../helpers/calc";
import RatesTable from "./RatesTable";

interface SummaryProps {
    amount: number;
    rateValue: number;
    ratesSum: number;
}

const Summary = ({amount, rateValue, ratesSum}: SummaryProps) => {

    return (
        <>
            <h2>Podsumowanie</h2>
            <div>
                wysokość początkowa raty: <b>{formattedCurrency(rateValue)}</b>
            </div>
            <br/>
            <div>
                Wartość kredytu: <b>{formattedCurrency(amount)}</b>
            </div>
            <div>
                Suma odsetek: <b>{formattedCurrency(ratesSum - amount)}</b>
            </div>
            <br/>
            <div>
                Całkowity Koszt Kredytu: <b>{formattedCurrency(ratesSum)}</b>
            </div>
            <div>
                Procentwo do oddania: <b>{formattedPercentage(ratesSum / amount)}</b>
            </div>
        </>
    )
}

export default Summary;