import rate, {formattedCurrency} from "../helpers/calc";

interface RatesTableProps {
    amount: number;
    interestRate: number;
    installments: number;
    overpay?: number;
    overpayPlus: boolean;
}



const RatesTable = ({amount, interestRate, installments, overpay = 0, overpayPlus}: RatesTableProps) => {

    let rates = [];
    let restAmount = amount;
    let restInstallments = installments;
    const {rateValue: startRateValue} = rate(restAmount, interestRate, restInstallments);

    while (restAmount > 0) {

        const calculatedRate =  rate(restAmount, interestRate, restInstallments);
        const {rateValue, interest, capital} = calculatedRate;

        let additionalOverpay = startRateValue - rateValue;
        
        restInstallments--;
        if (restAmount > 0) {
            rates.push({restAmount, startRateValue, rateValue, interest, capital, overpay, additionalOverpay});
        }
        restAmount -= (overpayPlus) ? (capital + overpay + additionalOverpay) : (capital + overpay);
    }
    
//    console.log(rates);


    return (
        <div>
            <b>przeliczona liczba rat: {rates.length}</b>
            <table>
                <thead>
                    <tr>
                        <td>Rata</td>
                        <td>Zadłużenie</td>
                        <td>Rata kapitałowa</td>
                        <td>Odsetki</td>
                        <td>Wysokość raty</td>
                        <td>Nadpłata</td>
                        <td>Nadpłata od raty!</td>
                    </tr>
                </thead>
                <tbody>
                {rates.map((rate, index) => {
                    const {restAmount, rateValue, capital, interest, additionalOverpay} = rate;
                    return (
                        <tr key={index + 1}>
                            <td>{index + 1}</td>
                            <td>{formattedCurrency(restAmount)}</td>
                            <td>{formattedCurrency(capital)}</td>
                            <td>{formattedCurrency(interest)}</td>
                            <td>{formattedCurrency(rateValue)}</td>
                            <td>{formattedCurrency(overpay)}</td>
                            <td>{formattedCurrency(additionalOverpay)}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

export default RatesTable;